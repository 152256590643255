import './App.css';
import PageViewer from './components/PageViewer/PageViewer';

function App() {
  return (
        <div className="app">
          <PageViewer />
        </div>
  );
}

export default App;

import './Skills.css'

function Skills(props) {
    return (
        <div className="skills">

            <div className="skill_header">
                I have experience with
            </div>

            <div className="skill_wrapper">
                <div className="skill_title">PHP</div>
                <div className="skill_extra">
                    Open-source contributor to WordPress, Magento, Laravel and other PHP projects.
                </div>    
            </div>

            <div className="skill_wrapper">
                <div className="skill_title">Python</div>
                <div className="skill_extra">
                    Multiple open source projects and work experience.
                </div>    
            </div>

            <div className="skill_wrapper">
                <div className="skill_title">Go</div>
                <div className="skill_extra">
                    Written libraries for and contributed to enterprise level Go APIs and scripts.
                </div>    
            </div>

            <div className="skill_header" id="other">
                along with other tools/languages
            </div>

            <div className="skill_other">
                AWS, GCP, Platform.sh, Jenkins, CircleCi, Javascript, React
            </div>

        </div>
    );
}

export default Skills;
import './HomePage.css'

function HomePage(props) {
    return (
        <div className="home">
            <div className="titleWrapper">
                <h1>Mark Berube</h1>
                <h2>Software Engineer</h2>
            </div>

            <p>
                Developer specializing in backend web technologies, DevOps, and security. Currently based in Austin and working at WP Engine as a Senior Software Engineer.
            </p>
        </div>
    );
}

export default HomePage;